
@import "variables";


/* ###### plugins ####### */
@import "plugins/autocomplete/jquery.autocomplete";
@import "plugins/charts-c3/c3-chart";
@import "plugins/chat/jquery.convform";
@import "plugins/color-picker/spectrum";
@import "plugins/datatable/dataTables.bootstrap5.min";
@import "plugins/datatable/jquery.dataTables.min";
@import "plugins/dropify/css/dropify";
@import "plugins/fancyuploder/fancy_fileupload";
@import "plugins/fullcalendar/fullcalendar";
@import "plugins/ion.rangeSlider/css/ion.rangeSlider";
@import "plugins/jquery-bar-rating/css/examples";
@import "plugins/jquery-bar-rating/css/normalize";
@import "plugins/jquery-countdown/countdown";
@import "plugins/jquery-growl/css/jquery.growl";
@import "plugins/jquery-uislider/jquery-ui";
@import "plugins/jquery.flexdatalist/jquery.flexdatalist";
@import "plugins/jqvmap/jqvmap";
@import "plugins/jvectormap/jquery-jvectormap-2.0.2";
@import "plugins/jvectormap/jqvmap";
@import "plugins/lightgallery/gallery";
@import "plugins/morris/morris";
@import "plugins/nice-select/nice-select";
@import "plugins/owl-carousel/owl.carousel";
@import "plugins/owl-carousel/owl.theme.default";
@import "plugins/perfect-scrollbar/perfect-scrollbar";
@import "plugins/prettycheckbox/pretty-checkbox.min";
@import "plugins/smart-wizard/smart_wizard";
@import "plugins/smart-wizard/smart_wizard_theme_arrows";
@import "plugins/smart-wizard/smart_wizard_theme_circles";
@import "plugins/smart-wizard/smart_wizard_theme_dots";
@import "plugins/sweet-alert/sweetalert";
@import "plugins/timeline/timeline";
@import "plugins/video/insideElementDemo";
@import "plugins/wildtime/wickedpicker.min";
@import "plugins/wysiwyag/richtext";
@import "plugins/primo-slider/primo-slider";
@import "plugins/ratings-2/demo";