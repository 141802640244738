@charset "UTF-8";
/*------------------------------------------------------------------

 * @Plugin Title :  PrimoSlider - Bootstrap Carousel Slider (No Jquery Dependency) 
 * @version      :  V.1.0(26/May/2022)
 * @author       :  Spruko Technologies Private Limited 
 * @autorURL     :  https://spruko.com/

-------------------------------------------------------------------*/
/* 

--- CAROUSEL-STYLES
--- SLIDER-STYLES
--- THUMB-STYLES
--- PRIMOSLIDER ANIMATIONS
--- RTL STYLES

*/
/* ###### CAROUSEL-STYLES ###### */
/* ###### SLIDER-STYLES ###### */
/* ###### THUMB-STYLES ###### */
/* ###### PRIMOSLIDER ANIMATIONS ###### */
/* RTL STYLES */
.carousel-item {
	transition: transform 0.5s ease, -webkit-transform 0.5s ease;
	position: relative;
	img {
		margin: 0 auto;
		top: 0;
		border-radius: 5px;
		width: 100%;
		object-fit: cover;
	}
	&::before {
		content: "";
		position: absolute;
		top: 0;
		inset-inline-start: 0;
		bottom: 0;
		inset-inline-end: 0;
		background: transparent;
	}
	iframe {
		z-index: 9;
		height: 35.3em;
		width: 100%;
	}
	video {
		width: 100%;
	}
}
.carousel-item-next {
	display: flex;
}
.carousel-item-prev {
	display: flex;
}
.carousel-item.active {
	display: flex;
	.primo-slider-fadeInLeft {
		>* {
			transform: translateX(0%);
			opacity: 1;
		}
	}
	.primo-slider-fadeInRight {
		>* {
			transform: translateX(0%);
			opacity: 1;
		}
	}
	.primo-slider-fadeInTop {
		>* {
			transform: translateY(0%);
			opacity: 1;
		}
	}
	.primo-slider-fadeInBottom {
		>* {
			transform: translateY(0%);
			opacity: 1;
		}
	}
}
.carousel-fade {
	.active.carousel-item-start {
		transition: all 0.5s ease !important;
	}
	.active.carousel-item-end {
		transition: all 0.5s ease !important;
	}
}
.slide-show-image {
	.carousel-item.active {
		display: flex !important;
		width: 100%;
	}
	display: flex !important;
	width: 100%;
	border-radius: 5px;
}
.carousel-item.carousel-item-start {
	&::before {
		z-index: -1;
	}
}
.carousel-indicators {
	margin: 0 !important;
	[data-bs-target] {
		box-sizing: content-box;
		flex: 0 1 auto;
		width: 100px;
		height: auto;
		padding: 0;
		margin-inline-end: 3px;
		margin-inline-start: 3px;
		cursor: pointer;
		background-color: $custom-white;
		background-clip: padding-box;
		border: 0;
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		transition: opacity 0.6s ease;
	}
}
[data-bs-dots=true] {
	.carousel-indicators {
		margin: auto !important;
	}
	.slider-wrap {
		height: 0 !important;
	}
	.dots {
		width: 0.6rem;
		height: 0.6rem;
		border-radius: 50%;
		opacity: 0.7;
	}
	.dots.active {
		background-color: red;
	}
}
.carousel-caption {
	font-size: 18px;
	font-weight: 400;
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 0px 5px;
	padding-block-end: 6px;
}
.carousel-control-next {
	bottom: inherit;
	top: 50%;
	transform: translateY(-50%);
	z-index: 99 !important;
}
.carousel-control-prev {
	bottom: inherit;
	top: 50%;
	transform: translateY(-50%);
	z-index: 99 !important;
}
.carousel {
	.primoslider-icon {
		color: rgba(255, 255, 255, 0.8);
		border: 1px solid rgba(0, 0, 0, 0.3);
		background: rgba(0, 0, 0, 0.5);
		padding: 5px;
		width: 38px;
		height: 38px;
		border-radius: 50px;
	}
	&:not([data-bs-dots=true]) {
		.slider {
			position: absolute;
			top: 0;
			inset-inline-start: 0;
			height: 5.5rem;
			width: 100%;
			overflow: scroll;
			transition: all 0.5s ease;
			touch-action: none;
			-ms-touch-action: none;
			&::before {
				content: "";
				display: block;
				inset-inline-start: 0;
				background: linear-gradient(to left, transparent 0%, rgba($black-rgb ,0.1) 100%);
				bottom: 0px;
				position: absolute;
				width: 50px;
				height: 100%;
				z-index: 3;
			}
			&::after {
				content: "";
				display: block;
				inset-inline-end: 0;
				background: linear-gradient(to right, transparent 0%, rgba($black-rgb ,0.1) 100%);
				bottom: 0px;
				position: absolute;
				width: 50px;
				height: 100%;
				z-index: 3;
			}
		}
		.slider-inner {
			position: absolute;
			display: flex;
			justify-content: center;
			top: 0;
			inset-inline-start: 0;
			width: fit-content;
			height: 4.5rem;
			transform: translateX(0);
			transition: all 1s ease;
		}
	}
	.thumb-carousel-control-prev {
		height: 1.7rem;
		top: 50px;
		transform: translateY(-50%);
		width: 1.7rem;
		opacity: 0.6;
		text-align: center;
		display: grid;
		.primoslider-icon {
			color: rgba(255, 255, 255, 0.8);
			margin: auto 0;
			width: 30px;
			height: 30px;
			font-size: 1.2rem;
		}
	}
	.thumb-carousel-control-next {
		height: 1.7rem;
		top: 50px;
		transform: translateY(-50%);
		width: 1.7rem;
		opacity: 0.6;
		text-align: center;
		display: grid;
		.primoslider-icon {
			color: rgba(255, 255, 255, 0.8);
			margin: auto 0;
			width: 30px;
			height: 30px;
			font-size: 1.2rem;
		}
	}
}
.slider-wrap {
	position: relative;
	margin: auto;
	height: 4.5rem;
	cursor: pointer;
	transition: all 0.5s ease;
	max-width: 100%;
	.thumb-carousel-control-prev {
		inset-inline-start: 0;
		z-index: 9999;
		height: 3rem;
		top: 3.3rem;
		width: 2rem;
		position: absolute;
	}
	.thumb-carousel-control-next {
		position: absolute;
		inset-inline-end: 0;
		z-index: 9999;
		height: 3rem;
		top: 3.3rem;
		width: 2rem;
	}
}
.carousel[data-bs-dots=true] {
	.slider {
		position: absolute;
		top: -40px;
		inset-inline-start: 0;
		height: 100%;
		width: 100%;
		transition: all 0.5s ease;
	}
	.slider-inner {
		position: absolute;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		top: 0;
		inset-inline-start: 0;
		width: fit-content;
		height: auto;
		transform: translateX(0);
		transition: all 1s ease;
	}
}
.slider {
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
	.carousel-caption {
		background-color: transparent;
		display: none !important;
	}
}
.product-slider {
	.slider-wrap {
		margin: 10px 0 0 0;
		padding: 0;
	}
}
.slider-inner {
	img {
		width: 100px;
		border-radius: 5px;
		border: 1px solid rgba(0, 0, 0, 0.5);
	}
	video {
		width: 100px;
		border-radius: 5px;
		border: 1px solid rgba(0, 0, 0, 0.5);
	}
}
.carousel-slide-1 {
	.slider-inner {
		img {
			height: 100px;
			width: 100px;
		}
	}
}
.fixed-slider {
	>.slider-inner {
		transform: translateX(0) !important;
		margin: auto !important;
	}
}
.thumb {
	margin: 0 0.6rem;
	position: relative;
}
.thumb-carousel-control-next.btn-disabled {
	display: none !important;
}
.thumb-carousel-control-prev.btn-disabled {
	display: none !important;
}
.thumb.item.active {
	img {
		border: 2px solid #ff0c0c;
		box-shadow: 0px 1px 9px 4px rgba(0, 0, 0, 0.15);
	}
	video {
		border: 2px solid #ff0c0c;
		box-shadow: 0px 1px 9px 4px rgba(0, 0, 0, 0.15);
	}
}
.fixed-slider+.thumb-carousel-control-prev {
	display: none !important;
	display: none !important;
}
.fixed-slider+.thumb-carousel-control-next {
	display: none !important;
}
.fixed-slider+.thumb-carousel-control-prev+.thumb-carousel-control-next {
	display: none !important;
}
[data-bs-video="true"] {
	.videothumb {
		&::after {
			content: '';
			background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLXBsYXktY2lyY2xlIj48Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIxMCI+PC9jaXJjbGU+PHBvbHlnb24gcG9pbnRzPSIxMCA4IDE2IDEyIDEwIDE2IDEwIDgiPjwvcG9seWdvbj48L3N2Zz4=);
			position: absolute;
			top: 50%;
			inset-inline-start: 50%;
			transform: translate(-50%, -50%);
			z-index: 99;
			background-repeat: no-repeat;
			width: 1.5rem;
			height: 1.5rem;
		}
	}
}
.carousel-indicator-custom-styles {
	.thumb.item.active {
		&::before {
			content: '';
			width: 13px;
			height: 13px;
			position: absolute;
			top: 0;
			inset-inline-end: 0;
			inset-inline-start: 0;
			margin: 0 auto;
			border: transparent 6px dashed;
			border-bottom: 6px solid #ff0c0c;
		}
	}
}
.thumb.item {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 4.5rem;
	top: 1%;
	overflow: hidden;
}
#carousel {
	.slide-show-image.carousel-inner {
		overflow: hidden;
		width: 100%;
		margin: 0 auto;
		border-radius: 5px;
	}
}
.slide-items {
	height: 5rem !important;
	margin: 0 5px !important;
	width: 100% !important;
	display: flex;
}
.thumb-carousel-control-prev {
	z-index: 99 !important;
}
.thumb-carousel-control-next {
	z-index: 99 !important;
}
.bg-primary-gradient {
	background: #3366ff;
}
.bg-background-color {
	.content-text {
		position: relative;
		z-index: 10;
	}
}
.demo-primo-slider {
	margin-block-start: 3rem;
}
.primo-slider-fadeInLeft {
	>* {
		transform: translateX(-100%);
		opacity: 0;
		transition: all 0.5s ease;
	}
}
.primo-slider-fadeInRight {
	>* {
		transform: translateX(100%);
		opacity: 0;
		transition: all 0.5s ease;
	}
}
.primo-slider-fadeInTop {
	>* {
		transform: translateY(-100%);
		opacity: 0;
		transition: all 0.5s ease;
	}
}
.primo-slider-fadeInBottom {
	>* {
		transform: translateY(100%);
		opacity: 0;
		transition: all 0.5s ease;
	}
}
@media (min-width:576px) {
	[data-bs-overlay=true] {
		.thumb {
			background-color: transparent;
			opacity: 0.7;
		}
		.slider-wrap {
			height: 0 !important;
			top: -100px;
		}
	}
	[data-bs-overlay-top=true] {
		.thumb {
			background-color: transparent;
			opacity: 0.7;
		}
	}
	.carousel[data-bs-overlay-top=true] {
		.slider {
			&::after {
				background: linear-gradient(to left, transparent 0%, transparent 100%) !important;
			}
			&::before {
				background: linear-gradient(to left, transparent 0%, transparent 100%) !important;
			}
		}
	}
	.carousel[data-bs-overlay=true] {
		.slider {
			&::after {
				background: linear-gradient(to left, transparent 0%, transparent 100%) !important;
			}
			&::before {
				background: linear-gradient(to left, transparent 0%, transparent 100%) !important;
			}
		}
	}
	[data-bs-overlay-top="true"] {
		.slider-wrap {
			height: 0 !important;
		}
	}
}
@media (max-width:480px) {
	.carousel {
		&:not([data-bs-dots=true]) {
			.slider {
				&::before {
					display: none;
				}
				&::after {
					display: none;
				}
			}
		}
	}
}
@media (max-width: 768px) {
	.slide-show-image {
		display: flex !important;
		width: 100%;
		.carousel-item.active {
			display: flex !important;
			width: 100%;
		}
	}
	#carousel {
		.slide-show-image.carousel-inner {
			overflow: hidden;
			width: 100%;
			margin: 0 auto;
			border-radius: 5px;
		}
	}
	.slide-items {
		height: 3rem !important;
	}
	.product-slider {
		.thumbcarousel {
			.carousel-item {
				.thumb {
					margin: 0 3px !important;
				}
			}
		}
	}
	.carousel-item {
		iframe {
			height: 28em !important;
		}
	}
}
.modal-dialog.modal-lg{
	.carousel-indicators [data-bs-target]{
		border-top: 2px solid transparent;
		border-bottom: 2px solid transparent;
	}
	.carousel:not([data-bs-dots=true]) .slider{
		height: 4.4rem;
	}

} 
.modal.fade.show {
	.slider-wrap .thumb-carousel-control-prev,
	.slider-wrap .thumb-carousel-control-next{
	    top:1.5rem;
	}
}