/*-------- Timeline -------*/

.timeline {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  margin-block-end: 50px;
  position: relative;
  table-layout: fixed;
  width: 100%;

  .time-show {
    margin-block-end: 30px;
    margin-inline-end: -75px;
    margin-block-start: 30px;
    position: relative;

    a {
      color: #ffffff;
    }
  }

  &:before {
    background-color: rgba(152, 166, 173, 0.3);
    bottom: 0px;
    content: "";
    inset-inline-start: 50%;
    position: absolute;
    top: 30px;
    width: 2px;
    z-index: 0;
  }

  .timeline-icon {
    -webkit-border-radius: 50%;
    background: #6481ae;
    border-radius: 50%;
    color: #ffffff;
    display: block;
    height: 21px;
    inset-inline-start: -54px;
    margin-block-start: -11px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 21px;

    i {
      color: #e0e8f5;
    }
  }

  .time-icon:before {
    font-size: 16px;
    margin-block-start: 5px;
  }
}

h3.timeline-title {
  color: #6481ae;
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 5px;
  text-transform: uppercase;
}

.timeline-item {
  display: table-row;

  &:before {
    content: "";
    display: block;
    width: 50%;
  }

  .timeline-desk .arrow {
    border-bottom: 8px solid transparent;
    border-inline-end: 8px solid #ffffff !important;
    border-top: 8px solid transparent;
    display: block;
    height: 0;
    inset-inline-start: -7px;
    margin-block-start: -10px;
    position: absolute;
    top: 50%;
    width: 0;
  }

  &.alt {
    &:after {
      content: "";
      display: block;
      width: 50%;
    }

    .timeline-desk {
      .arrow-alt {
        border-bottom: 8px solid transparent;
        border-inline-start: 8px solid #ffffff !important;
        border-top: 8px solid transparent;
        display: block;
        height: 0;
        inset-inline-start: auto;
        margin-block-start: -10px;
        position: absolute;
        inset-inline-end: -7px;
        top: 50%;
        width: 0;
      }

      .album {
        float: #{$float-right};
        margin-block-start: 20px;

        a {
          float: #{$float-right};
          margin-inline-start: 5px;
        }
      }
    }

    .timeline-icon {
      inset-inline-start: auto;
      inset-inline-end: -57px;
    }

    &:before {
      display: none;
    }

    .panel {
      margin-inline-start: 0;
      margin-inline-end: 45px;

      .panel-body p + p {
        margin-block-start: 10px !important;
      }
    }

    h4, p, .timeline-date {
      text-align: endt;
    }
  }
}

.timeline-desk {
  display: table-cell;
  vertical-align: top;
  width: 50%;

  h4 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }

  .panel {
    background: #ffffff;
    display: block;
    margin-block-end: 5px;
    margin-inline-start: 45px;
    position: relative;
    text-align: start;
    padding: 20px;
  }

  h5 span {
    color: #797979;
    display: block;
    font-size: 12px;
    margin-block-end: 4px;
  }

  p {
    color: #101010;
    font-size: 14px;
    margin-block-end: 0;
  }

  .album {
    margin-block-start: 12px;

    a {
      float: #{$float-left};
      margin-inline-end: 5px;
    }

    img {
      height: 50px;
      width: auto;
      border-radius: 3px;
    }
  }

  .notification {
    background: none repeat scroll 0 0 #ffffff;
    margin-block-start: 20px;
    padding: 8px;
  }
}

/** timeline left **/

.timelineleft {
  position: relative;
  margin: 0 0 30px 0;
  padding: 0;
  list-style: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background: rgba(152, 166, 173, 0.3);
    inset-inline-start: 31px;
    margin: 0;
    border-radius: 2px;
  }

  > li {
    position: relative;
    margin-inline-end: 10px;
    margin-block-end: 1.5rem;
  }
}

.timelinelft > li:before {
  content: " ";
  display: table;
}

.timelineleft > {
  li:after {
    content: " ";
    display: table;
  }

  .timeleft-label > span {
    font-weight: 600;
    padding: 5px;
    display: inline-block;
    background-color: #fff;
    color: #fff;
    border-radius: 4px;
  }

  li {
    &:after {
      clear: both;
    }

    > .timelineleft-item {
      -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 0 1px rgba(61, 119, 180, 0.12), 0 8px 16px 0 rgba(91, 139, 199, 0.24);
      border-radius: 3px;
      margin-block-start: 0;
      background: $custom-white;
      color: $text-color;
      margin-inline-start: 60px;
      margin-inline-end: 15px;
      padding: 0;
      position: relative;
      overflow: hidden;

      > {
        .time {
          color: #999;
          float: #{$float-right};
          padding: 10px;
          font-size: 12px;
        }

        .timelineleft-header {
          margin: 0;
          color: $text-color;
          border-bottom: 1px solid $border-color;
          padding: .75rem 1.25rem;
          font-size: 16px;
          line-height: 1.1;

          > a {
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.timelineleft-body img {
  width: 150px;
  height: 100px;
}

.timelineleft > li > .timelineleft-item > {
  .timelineleft-body, .timelineleft-footer {
    padding: .75rem 1.25rem;
  }
}

.timelineleft-body {
  font-size: 14px;
}

.timelineleft > li > {
  .fa, .glyphicon, .ion {
    width: 30px;
    height: 30px;
    font-size: 15px;
    line-height: 30px;
    position: absolute;
    color: #fff;
    background: #d2d6de;
    border-radius: 50%;
    text-align: center;
    inset-inline-start: 18px;
    top: 0;
  }
}